import React from 'react'
import {useFormik} from 'formik';
import { SubscribeSchema } from '../../schemas';
import { fetchNewsLetterForm } from '../../controler/footer/footer';

const initialValues ={
  fullname : '',
  email : '',
};

 function Subscribenewsletter() {
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik ({
    initialValues,
    validationSchema : SubscribeSchema,
    onSubmit : async (values, action) => {
      // console.log("formik Form Handling ", values);
      const data = await fetchNewsLetterForm(values);
      console.log("dataaaaaaaaa",data);
        action.resetForm();
    }
  })
  // console.log("formik Subscribe Form Handling ", errors);
//     const[newsletterform, setNewsLetterForm] = useState([]);

// useEffect(() => {
//     async function newsletterform() {
//         const data = await fetchNewsLetterForm();
//         setNewsLetterForm(data.data);
//         console.log("news letter form", data.data);

//     }
//     newsletterform();
// })
  return (
    <div className="subscribe-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                  <p>"We are under maintenance currently and developing this portal that shall resolve all your Forex requirements at a go. Kindly be patient with us and send your queries. " - Thank You!</p>
                    {/* <h3 data-aos="fade-up" data-aos-duration="1500">Subscribe to Our Newsletter</h3>
                    <form onSubmit={handleSubmit}>
                    <div className="subscribe-div" data-aos="fade-up" data-aos-duration="1500">
                        <div className="form-group">
                            <input 
                            type="text"  
                            name="fullname"
                            autoComplete='off'
                            className="form-control" 
                            placeholder="Your Name" 
                            value={values.fullname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <i className="fas fa-user"></i>
                            {errors.fullname && touched.fullname ? <p className="form-errors">{errors.fullname}</p> : null}
                        </div>
                        <div className="form-group">
                            <input type="text" 
                            name ="email"
                            autoComplete='off'
                            className="form-control" 
                            placeholder="Your Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <i className="fas fa-paper-plane"></i>
                            {errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
                        </div>
                        <button type="submit" className="btn btn-default">Subscribe</button>
                    </div>
                    </form> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Subscribenewsletter
