import React from 'react';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CountUp from 'react-countup';
// import ScrollTrigger from 'react-scroll-trigger';

import Subscribenewsletter from '../commonpage/Subscribenewsletter';
import Followus from '../footer/Followus';
import Footer from "../footer/Footer";
import Ournetwork from '../commonpage/Ournetwork';
import Marqueepage from '../marquee/Marqueepagee';
import Breadcrumbpage from '../commonpage/Breadcrumbpage';

import { fetchHome, fetchWhyQuickForex, fetchWhyQuickForexIcon, } from '../../controler/home/Home';
import Headertop from '../header/Headertop';
import Headermain from '../header/Headermain';
import Homebanner from '../banner/Homebanner';
// import Homeform from '../homeform/Homeform';
import { fetchOurServiceHome, fetchOurServiceSlider } from '../../controler/ourservices/ourservices';
import { fetchGetStartedHome, fetchGetStartedHomeStep } from '../../controler/getstarted/getstarted';
import { fetchTestimonialHomePage, fetchTestimonialSlider } from '../../controler/testimonial/testimonial';
import { imageUrl } from '../../controler/ApiHelper';
import Enquiry from '../homeform/Enquiry';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import Aos from 'aos';
// import 'aos/dist/aos.css'
function Home() {
	useEffect(() => {
		$(document).ready(function () {

			$('.mapmainclass').hide();
			$('.map1').show();
			$("input[name$='cars']").click(function () {
				var test = $(this).val();

				$("div.desc").hide();
				$("#Cars" + test).show();


			});

			$('.accordion-toggle').on('click', function () {
				let related_map = $(this).data('related-map');
				$('.mapmainclass').hide();
				$('.' + related_map).show();
			});
		});

		$('.ytvideo[data-video]').one('click', function () {
			var $this = $(this);
			// var width = $this.attr("width");
			// var height = $this.attr("height");

			$this.html('<iframe width="100%" height="360" src="https://www.youtube.com/embed/zQp708DMFlk?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>');
		});

	})

	// var mainheading = ["The World's Largest", <br/>, "Online Foreign Exchange"];

	// const customerlist = {
	// 	happycustomer: '4Lac+',
	// 	exchangedsofaar: '5,500 Cr+',
	// 	moneyexchanged:'5000+'
	// }
	// var url="http://localhost:8070";
	// const whyquickforex =[
	// 	{
	// 		whyquickheading: "Why Quick Forex",
	// 		whyquickpreg : "Quick Forex, is India premier online foreign exchange solutions provider, designed to suit the requirements of travelers, who cherish quick & easy modes of availing currency exchanges.",
	// 		slug:"/about",
	// 	},
	// ]

	// const whyquickforexfeature =[
	// 	{
	// 		whyquickimageURL: "assets/images/whyquickforex-icon1.png",
	// 		whyquickfeaturepreg : "Safe and Security",
	// 	},
	// 	{
	// 		whyquickimageURL: "assets/images/whyquickforex-icon2.png",
	// 		whyquickfeaturepreg : "Experts Support",
	// 	},
	// 	{
	// 		whyquickimageURL: "assets/images/whyquickforex-icon3.png",
	// 		whyquickfeaturepreg : "Instant Exchange",
	// 	},
	// 	{
	// 		whyquickimageURL: "assets/images/whyquickforex-icon4.png",
	// 		whyquickfeaturepreg : "Convenience",
	// 	},
	// ]

	// var ourservicehead = "Our Services";
	// var ourservicepreg = "We are an established foreign exchange company.";

	// const ourserviceslider =[
	// 	{
	// 		ourservicesliderimageURL: "assets/images/ourservices-icon1.png",
	// 		ourserviceslideriheading: "Buy Forex",
	// 		ourservicesliderpreg: "Quickest Currency Exchange at Best Rates",
	// 		slug: "/buyforex",
	// 	},
	// 	{
	// 		ourservicesliderimageURL: "assets/images/ourservices-icon1.png",
	// 		ourserviceslideriheading: "Sell Forex",
	// 		ourservicesliderpreg: "Quickest Currency Exchange at Best Rates",
	// 		slug: "/sellforex",
	// 	},
	// 	{
	// 		ourservicesliderimageURL: "assets/images/ourservices-icon2.png",
	// 		ourserviceslideriheading: "Remittance",
	// 		ourservicesliderpreg: "Quickest Currency Exchange at Best Rates",
	// 		slug: "/remittance",
	// 	},
	// 	{
	// 		ourservicesliderimageURL: "assets/images/ourservices-icon3.png",
	// 		ourserviceslideriheading: "Travel Card",
	// 		ourservicesliderpreg: "Quickest Currency Exchange at Best Rates",
	// 		slug: "/travelcard",
	// 	},
	// 	{
	// 		ourservicesliderimageURL: "assets/images/ourservices-icon3.png",
	// 		ourserviceslideriheading: "Travel Insurance",
	// 		ourservicesliderpreg: "Quickest Currency Exchange at Best Rates",
	// 		slug: "/travelinsurance",
	// 	}
	// ]
	// var getstartedheading = "Get Started";

	// const getstartedstep =[
	// 	{
	// 		getstartedstepimagesURL:"assets/images/getstarted-icon1.png",
	// 		getstartedstephead:"STEP-1",
	// 		getstartedsteppreg:"Select currency and denomination you need to carry along.",

	// 	},
	// 	{
	// 		getstartedstepimagesURL:"assets/images/getstarted-icon2.png",
	// 		getstartedstephead:"STEP-2",
	// 		getstartedsteppreg:"Select currency and denomination you need to carry along.",

	// 	},
	// 	{
	// 		getstartedstepimagesURL:"assets/images/getstarted-icon3.png",
	// 		getstartedstephead:"STEP-3",
	// 		getstartedsteppreg:"Select currency and denomination you need to carry along.",

	// 	}
	// ];
	// const[companyData, setCompanyData] = useState([]);
	// useEffect(() => {
	// 	fetch('https://forex.weaddo.com/api/home/general')
	// 	   .then((response) => response.json())
	// 	   .then((data) => {
	// 		  setCompanyData(data.data[0]);
	// 	   });
	//  }, []);

	//  const[whyquickData, setWhyquickData] = useState([]);
	// useEffect(() => {
	// 	fetch('https://forex.weaddo.com/api/why-quick-forex/general')
	// 	   .then((response) => response.json())
	// 	   .then((data) => {
	// 		  setWhyquickData(data.data[0]);
	// 	   });
	//  }, []);
	const [companyData, setCompanyData] = useState([]);
	const [whyquickData, setWhyQuickData] = useState([]);
	const [whyquickiconData, setWhyQuickIconData] = useState([]);
	const [ourservicehomeData, setOurServiceHomeData] = useState([]);
	const [ourservicesliderData, setOurServiceSliderData] = useState([]);
	const [getstartedhomeData, setGetStartedHomeData] = useState([]);
	const [getstartedhomestepData, setGetStartedHomeStepData] = useState([])
	const [testimonialhomepage, setTestimonialHomePage] = useState([])
	const [testimonialslider, setTestimonialSlider] = useState([])
	const [showModal, setShowModal] = useState()

	let options = {
		loop: true,
		margin: 10,
		nav: true,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 3
			},
			1000: {
				items: 4
			}
		}
	}
	// let options2={
	// 	loop: true,
	// 	margin:10,
	// 	nav:true,
	// 	responsive:{
	// 		0:{
	// 			items:1
	// 		},
	// 		600:{
	// 			items:3
	// 		},
	// 		1000:{
	// 			items:3
	// 		}
	// 	}
	// }
	// const[countStart, setCountStart] = useState(false)
	// const[Countnum, setCountNum] = useState(0)

	// const isEvenOdd = () => {
	// 	return(Countnum%2 === 0)
	// }



	useEffect(() => {
		async function getData() {
			const data = await fetchHome();
			setCompanyData(data.data);
		}
		getData()

		async function whyquickforexhomepageData() {
			const data = await fetchWhyQuickForex();
			setWhyQuickData(data.data[0]);
		}
		whyquickforexhomepageData()

		async function whyquickforexiconhomepageData() {
			const data = await fetchWhyQuickForexIcon();
			setWhyQuickIconData(data.data);
		}
		whyquickforexiconhomepageData()

		async function ourservicehomeData() {
			const data = await fetchOurServiceHome();
			setOurServiceHomeData(data.data);
		}
		ourservicehomeData()

		async function ourservicesliderData() {
			const data = await fetchOurServiceSlider();
			setOurServiceSliderData(data.data);
		}
		ourservicesliderData()

		async function getstartedhomeData() {
			const data = await fetchGetStartedHome();
			setGetStartedHomeData(data.data);
		}
		getstartedhomeData()

		async function getstartedhomestepData() {
			let array = []
			const data = await fetchGetStartedHomeStep();
			// console.log("dataaaaaaaaaaaaaaaaaaaaaaaa",data.data);
			for (let i = 0; i < data.data.length; i++) {
				if (data.data[i].pageName === "Home Page") {
					array.push(data.data[i])
				}
			}
			// console.log("iiiiiiiiiiiii",array);
			setGetStartedHomeStepData(array);
		}
		getstartedhomestepData()

		async function testimonialhomepage() {
			const data = await fetchTestimonialHomePage();
			setTestimonialHomePage(data.data[0]);

		}
		testimonialhomepage()

		async function testimonialslider() {
			const data = await fetchTestimonialSlider();
			setTestimonialSlider(data.data);
			console.log("testimonial", data.data);
		}
		testimonialslider()

		// Aos.init();
	}, []);

	return (
		<React.Fragment>
 {showModal &&<div className="thankyoumodal" role="dialog" onClick={()=>setShowModal(!showModal)}>
                {console.log("helloo")}
				<div className="modal-dialog">

					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" onClick={()=> setShowModal(!showModal)} data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
							<img src='assets/images/logo.png' alt=''/>
							<h2>Thank You!</h2>
							<p>Your submission has been received. a member of our staff will contact you shortly.</p>
						</div>
					</div>

				</div>
			</div>
}
			<Headertop />
			<div className="banner-main">
				<Headermain />
				<Homebanner />
				<Enquiry setShowModal={setShowModal} showModal={showModal} />
			</div>
			<Marqueepage />
			<Breadcrumbpage />
			<div className="worldlargest-main" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							{companyData.map((companyData) => (
								<>
									<h3 data-aos="fade-up" data-aos-duration="1500">{companyData.soTitle}</h3>
									<div className="worldlargest-div">
										<div className="worldlargest-leftdiv">
											<ul>
												<li data-aos="fade-up" data-aos-duration="1500"><small className="countpercent"><CountUp start={0} end={1} duration={10} delay={0} /></small> Lac+ <span>{companyData.ipText1}</span></li>
												<li data-aos="fade-down" data-aos-duration="1500"><small className="countpercent"><CountUp start={0} end={5500} duration={10} delay={0} /> Cr+</small> <span>{companyData.ipText2}</span></li>
												<li data-aos="fade-up" data-aos-duration="1500"><small className="countpercent"><CountUp start={0} end={5000} duration={10} delay={0} /> + </small> <span>{companyData.ipText3}</span></li>
											</ul>
											{/* <li data-aos="fade-down" data-aos-duration="1500">{companyData.ipCount2} <span>{companyData.ipText2}</span></li>
										<li data-aos="fade-up" data-aos-duration="1500">{companyData.ipCount3} <span>{companyData.ipText3}</span></li> */}
										</div>
										<div className="worldlargest-rightdiv">
											<div className="video-div" data-aos="fade-down" data-aos-duration="1500">
												<div id="ytvideoclick" className="ytvideo" data-video="7ixLP6mUqnw"
													style={{ width: "100%", height: "360px", marginTop: "0px", backgroundImage: `url(${'assets/images/video-img.jpg'})` }}></div>
												{/* <div className="ytvideo visible-xs" data-video="7ixLP6mUqnw"></div>
										<img src="assets/images/video-bg.jpg"/> */}
											</div>
										</div>
									</div>
								</>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="whyquickforex-main" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="whyquickforex-div">
								<div className="whyquickforex-leftdiv" data-aos="fade-down" data-aos-duration="1500">
									<h3>{whyquickData.title}</h3>
									<p>{whyquickData.description}</p>
									{/* <Link to="/?" className="btn btn-primary">Read More</Link> */}
									{/* {whyquickforex.map(whyquickforex =>{
							return(
								<>
								<h3>{whyquickforex.whyquickheading}</h3>
								<p>{whyquickforex.whyquickpreg}</p>
								<a href="#!" className="btn btn-primary">Read More</a>
								</>
							)
						})} */}

									{/* <h3>{whyquickforex.whyquickheading}</h3>
						<p>{whyquickforex.whyquickpreg}</p>
						<a href="#!" className="btn btn-primary">Read More</a> */}
								</div>
								<div className="whyquickforex-rightdiv">
									<ul>
										{whyquickiconData.slice(0, 4).map(whyquickiconData => {
											return (
												<li data-aos="fade-up" data-aos-duration="1500" key={whyquickiconData.id}>
													<img src={imageUrl + whyquickiconData.icon} width="0" height="0" alt="" title="" />
													<p>{whyquickiconData.icontitle}</p>
												</li>
											)
										})}
										{/* <li data-aos="fade-up" data-aos-duration="1500">
								<img src="assets/images/whyquickforex-icon1.png" width="0" height="0" alt="" title=""/>
								<p>Safe and Security</p>
							</li>
							<li data-aos="fade-down" data-aos-duration="1500">
								<img src="assets/images/whyquickforex-icon2.png" width="0" height="0" alt="" title=""/>
								<p>Experts Support</p>
							</li>
							<li data-aos="fade-down" data-aos-duration="1500">
								<img src="assets/images/whyquickforex-icon3.png" width="0" height="0" alt="" title=""/>
								<p>Instant Exchange</p>
							</li>
							<li data-aos="fade-up" data-aos-duration="1500">
								<img src="assets/images/whyquickforex-icon4.png" width="0" height="0" alt="" title=""/>
								<p>Convenience</p>
							</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ourservices-main" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							{ourservicehomeData.map((ourservicehomeData) => (
								<>
									<h3 data-aos="fade-up" data-aos-duration="1500">{ourservicehomeData.title}</h3>
									<p data-aos="fade-up" data-aos-duration="1500">{ourservicehomeData.description} </p>
								</>
							))}
							<OwlCarousel id='ourservices' className='offers owl-theme' {...options}
								nav
								dots={false}
								margin={20}
								autoplay={true} >
								{ourservicesliderData.map(ourservicesliderData => {
									return (
										<div className="item">
											<div className="ourservice-div">
												<img src={imageUrl + ourservicesliderData.homeIcon} width="0" height="0" alt="" title="" />
												<h4>{ourservicesliderData.title}</h4>
												<p>{ourservicesliderData.overviewDesc}</p>
												{/* <a href={ourservicesliderData.slug} className="btn btn-primary">Learn More <i className="fal fa-long-arrow-right"></i></a> */}
											</div>
										</div>
									)
								})}
							</OwlCarousel>

						</div>
					</div>
				</div>
			</div>
			<div className="getstarted-main" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							{getstartedhomeData.map((getstartedhomeData) => (
								<h3>{getstartedhomeData.title}</h3>
							))}

							<ul>
								{getstartedhomestepData.map((getstartedhomestepData) => {
									return (
										<li>
											<img src={imageUrl + getstartedhomestepData.icon} width="0" height="0" alt="" title="" />
											<h4>{getstartedhomestepData.text}</h4>
											<p>{getstartedhomestepData.desc}</p>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			< Ournetwork />
			<div className="whatourclientsays-main aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h3 data-aos="fade-up" data-aos-duration="1500">{testimonialhomepage.title}</h3>
							<p data-aos="fade-up" data-aos-duration="1500">{testimonialhomepage.description}</p>
							{testimonialslider.map(testimonialslider => {
								return (
									<div className='col-sm-6'>
										<div className="whatourclientsays-div">
											<img src={imageUrl + testimonialslider.image} alt="" title="" />
											<h4>{testimonialslider.name}</h4>
											<h6>{testimonialslider.position}</h6>
											<p>{testimonialslider.description}</p>
										</div>
									</div>
								)
							})}
							{/* <OwlCarousel id='whatourclientsays' className='offers owl-theme'
						loop  
						nav={false}
						dots={false}
						margin={20}
						autoplay ={true} >  
						{testimonialslider.map(testimonialslider => {
							return(
								<div className="item">
									<div className="whatourclientsays-div">
										<img src={imageUrl+testimonialslider.image} alt="" title=""/>
										<h4>{testimonialslider.name}</h4>
										<h6>{testimonialslider.position}</h6>
										<p>{testimonialslider.description}</p>
									</div>
								</div>
							)
						})}
					</OwlCarousel> */}
						</div>
					</div>
				</div>
			</div>
			<Subscribenewsletter />
			<Followus />
			<Footer />
		</React.Fragment>
	);
}

export default Home;
