import React, { useEffect, useState } from "react";
import { fetchFollowUs } from "../../controler/footer/footer";
import Headertop from "../header/Headertop";
import Footer from "../footer/Footer";
import Followus from "../footer/Followus";

function ReturnPloicy() {
  const [followus, setFollowUs] = useState([]);

  useEffect(() => {
    async function followus() {
      const data = await fetchFollowUs();
      setFollowUs(data.data);
    }
    followus();
  }, []);
  return (
    <>
    <Headertop />
    <div className="buforex-main staticpages" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
      <h2 style={{textAlign:"center"}}>Refund Policy</h2>

      <hr />

      <p>
        For order cancelled on customer's request or non-compliance of the KYC
        Regulations by the customer, the amount will be refunded after deduction
        of payment gateway charges. Payment Gateway charge is a fee also known
        as 'Merchant Service fee', and is applicable for all transactions where
        customer makes payment online. Currently this fee varies from one mode
        of payment to another and is subject to change as decided by respective
        Payment Gateway.
      </p>

      <p>
        In case of orders placed through Partial payment request, order
        confirmation fee of 2% will not be refunded.
      </p>
      </div>
      </div>
      </div>
    </div>
    <Followus />
    <Footer />
    </>
  );
}

export default ReturnPloicy;
