import React, { useEffect, useState } from 'react'
import "jquery-ui-dist/jquery-ui";
import { Link, useNavigate } from 'react-router-dom'
import Sidesticky from '../commonpage/Sidesticky';
import { fetchHamburgerServices, fetchHeaderHamburgerMenu, fetchHeaderMenu, fetchLogo } from '../../controler/header/header';
import { fetchFollowUs } from '../../controler/footer/footer';
import { imageUrl } from '../../controler/ApiHelper';
function Headermain() {
	// var url = "https://forex.weaddo.com";
	const [headermenu, setHeaderMenu] = useState([])
	const [headerhamburgermenu, setHeaderHamburgerMenu] = useState([])
	const [followus, setFollowUs] = useState([]);
	const [logo, setLogo] = useState([])
	const [hamburgerservices, setHamburgerServices] = useState([])
	const navigate = useNavigate();

	// useEffect(() => {
	// 	var btn = document.querySelector('.togglenewone');
	// var btnst = true;
	// btn.onclick = function () {
	// 	if (btnst === true) {
	// 		document.querySelector('.togglenewone span').classList.add('togglenewone');
	// 		document.getElementById('sidebarupdtedone').classList.add('sidebarshow');
	// 		btnst = false;
	// 	} else if (btnst === false) {
	// 		document.querySelector('.togglenewone span').classList.remove('togglenewone');
	// 		document.getElementById('sidebarupdtedone').classList.remove('sidebarshow');
	// 		btnst = true;
	// 	}
	// }
	// });
	// const arraymenu=[
	// 	{
	// 		title:"Buy Forex",
	// 		slug:"/about"
	// 	},
	// 	{
	// 		title:"Sell Forex",
	// 		slug:"hjkhjkkl"
	// 	},
	// 	{
	// 		title:"Forex Rates",
	// 		slug:"hjkhjkkl"
	// 	},
	// 	{
	// 		title:"Currency Converter",
	// 		slug:"hjkhjkkl"
	// 	}
	// ]
	useEffect(() => {
		async function headermenu() {
			const data = await fetchHeaderMenu();
			setHeaderMenu(data.data);
		}
		headermenu();

		async function headerhamburgermenu() {
			const data = await fetchHeaderHamburgerMenu();
			setHeaderHamburgerMenu(data.data);
		}
		headerhamburgermenu();

		async function followus() {
			const data = await fetchFollowUs();
			setFollowUs(data.data);
		}
		followus();

		async function logo() {
			const data = await fetchLogo();
			setLogo(data.data[0]);
		}
		logo();
		async function hamburgerservices() {
			const data = await fetchHamburgerServices();
			setHamburgerServices(data.data);
			// console.log("hamburger Service menu list showwwwwwwwwwww", data.data);
		}
		hamburgerservices()

	}, [])

	return (
		<>
			<div id="myModal" className="modal fade" role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
						</div>
						<div className="modal-body">
							<div className='container'>
								<div className='row'>
									<div className="col-lg-4 col-sm-5">
										<div className="popuplistfirst">
											<ul>
												{headerhamburgermenu.map(headerhamburgermenuu => {
													return (
														<li key={headerhamburgermenu.indexOf(headerhamburgermenuu)} onClick={() => navigate(headerhamburgermenuu.slug)}>{headerhamburgermenuu.title}</li>
													)
												})}
												{/* <li><Link to="/">Home</Link></li>
						<li><Link to="/about">About Us</Link></li>
						<li><Link to="/award">Awards</Link></li>
						<li><Link to="/blog">Blogs</Link></li>
						<li><Link to="/faq">FAQ's</Link></li>
						<li><Link to="contact">Contact</Link></li> */}
											</ul>
										</div>
										<div className="popuplistsocial">
											<h4>Follow Us</h4>
											<ul>
												{followus.map(followuslist => {
													return (
														<li key={followus.indexOf(followuslist)}><a href={followuslist.icon_url} onClick={(e) => { e.preventDefault(); window.open(followuslist.icon_url, '_blank'); }}><i className={followuslist.icon_alt}></i></a></li>
													)
												})}
												{/* <li><Link to=""><i className="fab fa-facebook-f"></i></Link></li>
						<li><Link to=""><i className="fab fa-instagram"></i></Link></li>
						<li><Link to=""><i className="fab fa-pinterest-p"></i></Link></li>
						<li><Link to=""><i className="fab fa-linkedin-in"></i></Link></li>
						<li><Link to=""><i className="fab fa-twitter"></i></Link></li> */}
											</ul>
										</div>
									</div>
									<div className="col-lg-4 col-sm-7">
										<div className="popuplistsecond">
											<h3>Our Services</h3>
											<ul>
												{hamburgerservices.map(hamburgerservicess => {
													return (
														<li key={hamburgerservices.indexOf(hamburgerservicess)}><Link to={hamburgerservicess.slug}>{hamburgerservicess.title}</Link></li>
													)
												})}
												{/* <li><Link to="/">Current Exchange</Link>
							<ul>
								<li><Link to="/buyforex">Buy</Link></li>
								<li><Link to="/sellforex">Sell</Link></li>
							</ul>
						</li>
						<li><Link to="/remittance">Remittance</Link></li>
						<li><Link to="/Travelcard">Travel Card</Link></li>
						<li><Link to="/Travelinsurance">Travel Insurenace</Link></li> */}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			{/* <Sidesticky /> */}
			<div className="header-nav headerdiv">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<nav className="navbar navbar-default">
								<div className="navbar-header" data-aos="fade-down">
									{/* <button type="button" className="togglenewone visible-xs" id="togglenewone">
										<span></span>
									</button> */}
									<Link to="/home" className="navbar-brand"><img src={imageUrl + logo.logo} width="0" height="0" alt="" title="" />
									</Link>
								</div>
								<div className="collapse navbar-collapse" id="myNavbar">
									{/* <ul className="nav navbar-nav navbar-right">
										{headermenu.map(headermenuone => {
											return (
											// <li key={headermenu.indexOf(headermenuone)} onClick={()=>navigate(headermenuone.slug)}>{headermenuone.title}</li>
											<li key={headermenu.indexOf(headermenuone)}><Link to={headermenuone.slug}> {headermenuone.title}</Link></li>
											)
										})}
										<li><a href="!#" className="btn open-popup" data-animation="scale" data-toggle="modal" data-target="#myModal">
												<img src="assets/images/toggle-icon.png" className="toggle-img" width="0" height="0" 
												alt="" title="" /> </a></li>
										</ul> */}
									{/* <ul className="nav navbar-nav navbar-right">
											
											<li><Link to="/about">Buy Forex  </Link></li>
											<li><a href="sell-forex.html">Sell Forex  </a></li>
											<li><a href="/">Forex Rates  </a></li>
											<li><a href="/">Currency Converter</a></li>
											<li><a href="/" className="btn open-popup" data-id="popup_2" data-animation="scale">
												<img src="assets/images/toggle-icon.png" className="toggle-img" width="0" height="0" 
												alt="" title="" /> </a></li>
										</ul> */}
								</div>
								<div className="sidebarupdtedone" id="sidebarupdtedone">
									<div className="sidebarupdtedonemain">
										<div className="sidebarupdtedone-leftdiv">
											<ul>
												{headerhamburgermenu.map(headerhamburgermenuu => {
													return (
														<li key={headerhamburgermenu.indexOf(headerhamburgermenuu)}><Link to={headerhamburgermenuu.slug}>{headerhamburgermenuu.title}</Link></li>
													)
												})}
												{/* <li><a href="index.html">Home</a></li>
													<li><a href="about-us.html">About us</a></li>
													<li><a href="awards.html">Awards</a></li>
													<li><a href="blog.html">Blogs</a></li>
													<li><a href="our-team.html">Meet The Team</a></li>
													<li><a href="our-services.html">Our Services  </a></li>
													<li><a href="faq.html">FAQ'S</a></li>
													<li><a href="contact-us.html">Contact Us</a></li> */}
											</ul>
										</div>
										<div className="sidebarupdtedone-leftdiv">
											<ul>
												{hamburgerservices.map(hamburgerservicess => {
													return (
														<li key={hamburgerservices.indexOf(hamburgerservicess)}><Link to={hamburgerservicess.slug}>{hamburgerservicess.title}</Link></li>
													)
												})}
											</ul>
										</div>
									</div>
									<div className="popuplistsocial">
										<h4>Follow Us</h4>
										<ul>
											{followus.map(followuslist => {
												return (
													<li key={followus.indexOf(followuslist)}><a href={followuslist.icon_url} onClick={(e) => { e.preventDefault(); window.open(followuslist.icon_url, '_blank'); }}><i className={followuslist.icon_alt}></i></a></li>
												)
											})}
										</ul>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Headermain
