import React, { useEffect, useState } from 'react'
import { fetchHeaderTop } from '../../controler/header/header';
import { imageUrl } from '../../controler/ApiHelper';

function Headertop() {
    // var url="https://forex.weaddo.com";
    const[headertop, setHeaderTop] = useState([])

    useEffect(() => {
        async function headertop () {
            const data = await fetchHeaderTop();
            setHeaderTop(data.data);
        }
        headertop();
    }, [])
  return (
        <div className="header-top">
            <div className="container">
                <ul>
                    {headertop.map(headertopone => {
                        return(
                            <li key={headertop.indexOf(headertopone)}><a href={headertopone.slug}><img src={imageUrl+headertopone.icon} width="0" height="0" alt="" title=""/> {headertopone.title} </a></li>
                        )
                    })}
                    {/* <li><a href="javascript0"><img src="assets/images/home-icon.png" width="0" height="0" alt="" title=""/> Home </a></li>
                    <li><a href="javascript0"><img src="assets/images/mail-icon.png" width="0" height="0" alt="" title=""/> online@quickforex.in </a></li>
                    <li><a href="javascript0"><img src="assets/images/customer-icon.png" width="0" height="0" alt="" title=""/> 1800-222-111 </a></li> */}
                </ul>
            </div>
        </div>
  )
}

export default Headertop
