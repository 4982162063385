import React, { useEffect, useState } from "react";
import { fetchFollowUs } from "../../controler/footer/footer";
import Headertop from "../header/Headertop";
import Footer from "../footer/Footer";
import Followus from "../footer/Followus";

function PrivacyPolicy() {
  const [followus, setFollowUs] = useState([]);

  useEffect(() => {
    async function followus() {
      const data = await fetchFollowUs();
      setFollowUs(data.data);
    }
    followus();
  }, []);
  return (
    <>
    <Headertop />
    <div className="buforex-main staticpages" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
      <h2 style={{textAlign:"center"}}>Privacy Policy</h2>

      <p>
        Quick Forex Limited (hereinafter referred to as “Quick Forex”) is
        committed to protecting user privacy during visits to our website and
        recognizes our responsibility to hold your information secure and
        confidential.
      </p>

      <p>
        This Privacy Policy is published in accordance with the provisions of
        Rule 4(1) of the Information Technology (Reasonable Security Practices
        and Procedures and Sensitive Personal Data or Information) Rules, 2011
        and as per the provisions of Master Directions issued by Reserve Bank of
        India from time to time, which requires the publishing of a privacy
        policy for handling of or dealing in personal information including
        sensitive personal data or information of the customers/users
      </p>

      <p>
        <b>Information Quick Forex collects and controls </b>
      </p>

      <p>
        We only collect the information that we actually need. Some of it is
        information that you actively give us when you sign up for an account,
        ask for customer support, or buy something from us. We store your basic
        details like name, contact information, address etc. but we don't store
        any payment/confidential details of yours.{" "}
      </p>

      <p>
        Sometimes we receive information indirectly. If you ask about our
        products through one of our referral programs or reselling partners, or
        sign in to one of our products through an authentication service
        provider like LinkedIn or Google, they'll pass on your contact
        information to us. We'll use that information to complete the request
        that you made
      </p>

      <p>
        <b>What we do with your information</b>
      </p>

      <p>
        We use your information to provide the services you've requested, create
        and maintain your accounts, and keep an eye out for unauthorized
        activity on your accounts. We also use it to communicate with you about
        the products you're currently using, your customer support requests, new
        products you may like, chances for you to give us feedback, and policy
        updates. We analyze the information we collect to understand user needs
        and to improve our websites and services.
      </p>

      <p>
        Quick Forex does not share your Personal Information with third parties
        for marketing purposes without seeking your prior permission. Quick
        Forex will seek your consent prior to using or sharing Personal
        Information for any purpose beyond the requirement for which it was
        originally collected. Quick Forex may share your Personal Information to
        other third parties in the following cases:
      </p>

      <ol>
        <li>To comply with a legal obligation</li>
        <li>{`To Prevent fraud or protect our user’s safety.`}</li>
        <li>
          To obtain advice from third parties such as Auditors & legal Advisors.
        </li>
        <li>To merge or associate with another company.</li>
      </ol>

      <p>
        <b>Third Party Sites</b>
      </p>

      <p>
        Third-party websites and social media widgets have their own separate
        privacy policies. Always check the relevant privacy policy before
        sharing personal information with third parties. Quick Forex does not
        cover any third party sites whose link may be provided on Quick Forex
        site.
      </p>

      <p>
        <b>Notification of Changes to Privacy Policy</b>
      </p>

      <p>
        This privacy policy may be changed from time to time so please read it
        periodically. Quick Forex reserve their right to change/amend this
        policy and update it as and when requires.
      </p>
      </div>
      </div>
      </div>
    </div>
    <Followus />
    <Footer />
    </>
  );
}

export default PrivacyPolicy;
