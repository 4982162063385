import React, { useEffect, useState } from 'react'
import { fetchSideSticky } from '../../controler/sidesticky/sidesticky';
import { imageUrl } from '../../controler/ApiHelper';

function Sidesticky() {
  // var url = "http://localhost:8070";
  const[sidesticky, setSideSticky] = useState([])

  useEffect(() =>{
    async function sidesticky(){
      const data = await fetchSideSticky();
      setSideSticky(data.data);
    }
    sidesticky();
  }, [])
// console.log("iagesssssssssssss", imageUrl);
  return (
    <div className="sidedivfixed">
        <ul>
          {sidesticky.map(sidestickyy => {
            return(
              <li key={sidesticky.indexOf(sidestickyy)}><a href={sidestickyy.icon_url}><img src={imageUrl+sidestickyy.icon} width="0" height="0" alt="" title=""/></a></li>
            )
          })}
            {/* <li><a href="!#"><img src="assets/images/sidedivfixed-icon1.png" width="0" height="0" alt="" title=""/></a></li>
            <li><a href="!#"><img src="assets/images/sidedivfixed-icon2.png" width="0" height="0" alt="" title=""/></a></li>
            <li><a href="!#"><img src="assets/images/sidedivfixed-icon3.png" width="0" height="0" alt="" title=""/></a></li>
            <li><a href="!#"><img src="assets/images/sidedivfixed-icon4.png" width="0" height="0" alt="" title=""/></a></li> */}
        </ul>
    </div>
  )
}

export default Sidesticky
