import React, { useEffect, useState } from "react";
import { fetchFollowUs } from "../../controler/footer/footer";
import Headertop from "../header/Headertop";
import Footer from "../footer/Footer";
import Followus from "../footer/Followus";

function TermsAndCondition() {
  const [followus, setFollowUs] = useState([]);

  useEffect(() => {
    async function followus() {
      const data = await fetchFollowUs();
      setFollowUs(data.data);
    }
    followus();
  }, []);
  return (
    <>
      <Headertop />
    <div className="buforex-main staticpages" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
      <h2 style={{textAlign:"center"}}>General Terms and Conditions</h2>

      <ul>
        <li>
          QUICK FOREX LTD will not be responsible for any fraudulent transaction
          executed through us by forge or unlawful information. User will be
          solely responsible for such action and liable to regulatory actions
        </li>
        <li>
          All orders need to be completely complied with laid down RBI rules
          under Section 10 of the Foreign Exchange Management Act, 1999. any
          deviation from the same will be the sole responsibility of the user.
          (If you need any information on guidelines please write/call us on{" "}
          <a href="mailto: support@quickforex.com">support@quickforex.com</a> ).
        </li>
        <li>Deliveries only to the area covered by QUICK FOREX LTD</li>
        <li>
          Rates are subjected to change without notice and would be updated
          realtime.
        </li>
        <li>
          User understands and agrees that all other foreign currency travel
          card related terms and condition as displayed on the card issuing
          banks website will be applicable.
        </li>
        <li>
          The orders placed are not designed for the purpose of any
          contravention or evasion of the provisions of the FEMA 1999 Act or of
          any Rule, Regulation, Notification, Direction or Order made there
          under.
        </li>
        <li>
          The customer shall be responsible and liable for any incorrect
          information provided by him
        </li>
        <li>
          The foreign exchange purchased from this site should be used within 60
          days of purchase. In case it is not possible to use the foreign
          exchange within the period of 60 days, same should be surrendered to
          an authorized person.
        </li>
        <li>
          Doorstep delivery would be subjected to a service charge ranging from
          Rs. 100 till Rs. 1000 depending on the location and amount of foreign
          exchange requested . Forex delivery would be executed from Mon –
          Saturday (10am till 7pm) except Sundays and Bank Holidays.
        </li>
        <li>
          QUICK FOREX LTD. would deliver currencies wherever they have servicing
          Branch
        </li>
        <li>
          {" "}
          All documents are mandatory. A2/LRS form and Bank reload forms need to
          be submitted for every transaction.
        </li>
        <li>
          Payment needs to be processed within half an hour of placing the order
          or else the rates will be applicable when the complete payment and
          documentation is received.
        </li>
        <li>Destination currency notes are subject to availability</li>
      </ul>

      <p>
        Please read these Terms and Conditions carefully before using the QUICK
        FOREX LTD website. Your access to and use of the Service is conditioned
        on your acceptance of and compliance with these Terms. These Terms apply
        to all visitors, users and others who access or use the Service. By
        accessing or using the Service you agree to be bound by these Terms. If
        you disagree with any part of the terms then you may not access the
        Service.
      </p>

      <p>
        You will not modify copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell any information, software, products or services obtained from this
        Web site.
      </p>

      <p>
        <b>Accounts</b>
      </p>

      <p>
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service. You are responsible for
        safeguarding the password that you use to access the Service and for any
        activities or actions under your password, whether your password is with
        our Service or a third-party service. You agree not to disclose your
        password to any third party. You must notify us immediately upon
        becoming aware of any breach of security or unauthorized use of your
        account.
      </p>

      <p>
        <b>Termination of Access</b>
      </p>

      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. All provisions of the Terms which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability. We may terminate or
        suspend your account immediately, without prior notice or liability, for
        any reason whatsoever, including without limitation if you breach the
        Terms. Upon termination, your right to use the Service will immediately
        cease. If you wish to terminate your account, you may simply discontinue
        using the Service. All provisions of the Terms which by their nature
        should survive termination shall survive termination, including, without
        limitation, ownership provisions, warranty disclaimers, indemnity and
        limitations of liability.
      </p>

      <p>
        <b>Governing Law</b>
      </p>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Delhi ,India without regard to its conflict of law provisions.
      </p>

      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.
      </p>

      <p>
        <b>Changes / Updates</b>
      </p>

      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>

      <p>
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the Service.
      </p>

      <p>
        <b>Contact /Mail us</b>
      </p>

      <p>
        If you have any questions about these Terms please mail at{" "}
        <a href="mailto: support@quickforex.in">support@quickforex.in</a>
      </p>
      </div>
      </div>
      </div>
    </div>
    <Followus />
    <Footer />
    </>
  );
}

export default TermsAndCondition;
